import {currencySymbol} from '../utils';

export default {
  methods: {
    // 订单价格的计算等
    formatOrder(order) {
      const days = (order.days || order.lates);
      order.totalPrice = 0;
      order.billingType = currencySymbol(order.billingType);
      order.adultPrice = (order.adultPrice || 0) * 100;
      order.elderlyPrice = (order.elderlyPrice || 0) * 100;
      order.childPrice = (order.childPrice || 0) * 100;
      order.notChildPrice = (order.notChildPrice || 0) * 100;
      order.babyPrice = (order.babyPrice || 0) * 100;
      order.singleBedPrice = (order.singleBedPrice || 0) * 100;
      if (order.isInsurance === 'Y') {
        order.insuranceAdultPrice = (order.adultInsurancePrice || 0) * 100;
        order.insuranceElderlyPrice = (order.elderlyInsurancePrice || 0) * 100;
        order.insuranceChildPrice = (order.childInsurancePrice || 0) * 100;
        order.insuranceAdultTotalPrice = order.insuranceAdultPrice * days * order.adultNumber / 100;
        order.insuranceElderlyTotalPrice = order.insuranceElderlyPrice * days * order.elderlyNumber / 100;
        order.insuranceChildTotalPrice = order.insuranceChildPrice * days * (order.childNumber + order.notChildNumber + order.babyNumber) / 100;
        order.totalPrice += order.insuranceAdultTotalPrice * 100;
        if (order.priceType !== '1') {
          order.totalPrice += order.insuranceChildTotalPrice * 100;
          order.totalPrice += order.insuranceElderlyTotalPrice * 100;
        }
      }
      order.totalPrice += order.adultNumber * order.adultPrice + order.singleBedNumber * order.singleBedPrice;
      if (order.priceType !== '1') {
        order.totalPrice += order.elderlyPrice * order.elderlyNumber + order.childNumber * order.childPrice + order.notChildNumber * order.notChildPrice + order.babyNumber * order.babyPrice;
      }
      order.totalPrice = order.totalPrice / 100;
      return order;
    },
    // 按旅客类型生成旅客列表
    createPassengerList(number, travelerType, isOccupyBed) {
      let list = [];
      for (let i = 0; i < number; i++) {
        list.push({
          isCalled: '',
          englishSurname: '',
          englishName: '',
          chineseSurname: '',
          chineseName: '',
          genderType: '',
          paperworkType: '',
          otherPaperwork: '',
          issuingCountry: '',
          idNumber: '',
          effectiveDate: '',
          birthday: '',
          phoneType: '',
          userPhone: '',
          travelerType,
          isOccupyBed,
          isFight: 'N'
        });
      }
      return list;
    },
    // 生成联系人信息
    createContactInformation(contactList, userType = 'ordinary') {
      const isUrgent = false;
      const current = contactList.find(item => item.userType === userType);

      if (current && userType === 'urgent') {
        current.isUrgent = true;
      }
      return current || {
        userType,
        isUrgent,
        isCalled: '',
        englishSurname: '',
        englishName: '',
        chineseSurname: '',
        chineseName: '',
        phoneType: '',
        userPhone: '',
        userEmail: ''
      };
    },
    // 根据userType生成联系人列表（userType=（ordinary，联系人）||（urgent，紧急联系人））
    createContactInformationList(contactList, userType = 'ordinary') {
      // const isUrgent = false;
      const current = contactList.filter(item => item.userType === userType);
      return current;
    }
  }
};
