<template>
  <div class="main">
    <h2 class="h2">{{$t('Group application')}}</h2>
    <div class="order-info cl">
      <!--訂單編號-->
      <div class="item">{{$t('Order number')}}：{{orderInfo.orderNo}}</div>
      <!--產品編號-->
      <div class="item">{{$t('ProductNumber')}}：{{orderInfo.productNo}}</div>
      <!--產品名稱-->
      <div class="item">{{$t('ProductName')}}：{{orderInfo.productTitle}}</div>
      <!--出發日期-->
      <div class="item">{{$t('DepartureDate')}}：{{orderInfo.priceDate}}</div>
    </div>
    <h2 class="h2 m-t">{{$t('Transfer to')}}</h2>
    <div class="product-info">
      <div class="search-wrap cl">
        <!--产品编号-->
        <div class="item">
          <div class="label">{{$t('ProductNumber')}}：</div>
          <div>
            <el-select class="select"
                       :placeholder="$t('PleaseEnter')"
                       v-model="productId"
                       size="small"
                       filterable
                       remote
                       reserve-keyword
                       :remote-method="searchProductsForNumber"
                       :loading="loadingList">
              <el-option v-for="(item,index) of products"
                         :key="index||item.productId"
                         :label="item.productCode"
                         :value="item.productId">
                <span class="fl">{{item.productCode}}</span>
                <span class="fr">{{item.title}}</span>
              </el-option>
            </el-select>
          </div>
        </div>
        <!--产品名称-->
        <div class="item">
          <div class="label">{{$t('ProductName')}}：</div>
          <div>
            <el-select class="select"
                       :placeholder="$t('PleaseEnter')"
                       v-model="productId"
                       size="small"
                       filterable
                       remote
                       reserve-keyword
                       :remote-method="searchProductsForName"
                       :loading="loadingList">
              <el-option v-for="(item,index) of products"
                         :key="index||item.productId"
                         :label="item.title"
                         :value="item.productId">
                <span class="fl">{{item.title}}</span>
                <span class="fr">{{item.productCode}}</span>
              </el-option>
            </el-select>
          </div>
        </div>
        <!--出發日期-->
        <div class="item">
          <div class="label">{{$t('DepartureDate')}}：</div>
          <div>
            <el-date-picker class="select" type="date" size="small"
                            :disabled="!productId"
                            :picker-options="pickerOptions"
                            value-format="yyyy-MM-dd"
                            :clearable="false"
                            :placeholder="$t('PleaseChoose')"
                            v-model="departureDate">
            </el-date-picker>
          </div>
        </div>
        <!--路線-->
        <div class="item">
          <div class="label">{{$t('Route')}}：</div>
          <div>
            <el-select class="select" v-model="lineId" size="small" :disabled="!departureDate"
                       :placeholder="$t('PleaseChoose')" @change="lineChange">
              <el-option v-for="item in lines"
                         :key="item.lineId"
                         :label="item.lineTitle"
                         :value="item.lineId"
                         :disabled="item.lastPeopleNumber<=0">
                <span style="float:left">{{item.lineTitle}}</span>
                <span style="float:right;color:#c0c4cc;font-size:12px">{{item.memo}}</span>
              </el-option>
            </el-select>
          </div>
        </div>
        <!--報團人數-->
        <div class="item">
          <div class="label">{{$t('TourGroupsNumber')}}：</div>
          <div style="line-height:32px" v-if="currentLine">
            {{currentLine.limitPeopleNumber-currentLine.lastPeopleNumber||0}}／{{currentLine.isLimitPeople==='N'?$t('NotSet'):currentLine.limitPeopleNumber}}
          </div>
        </div>
        <div class="item" style="float:right">
          <div class="label"></div>
          <div>
            <button class="button small" type="button" @click="clearData">{{$t('Clear')}}</button>
            <!--<button class="button button-primary small" type="button">{{$t('search')}}</button>-->
          </div>
        </div>
      </div>
      <div class="search-result" v-if="productInfo">
        <table>
          <tr>
            <!--產品編號-->
            <td>{{$t('ProductNumber')}}：{{productInfo.productCode}}</td>
            <!--產品名稱-->
            <td>{{$t('ProductName')}}：{{productInfo.title}}</td>
            <!--路線名稱-->
            <td>{{$t('Route')}}：{{lineTitle}}</td>
          </tr>
          <tr>
            <!--出發日期-->
            <td>{{$t('DepartureDate')}}：{{departureDate}}</td>

          </tr>
        </table>
      </div>
    </div>
    <!--旅客資料-->
    <div class="fl-group">
      <div class="hd cl">
        <!--<div class="fr">
          <a class="btn" @click="openModal('modifyPassenger')">{{$t('Modify Information')}}</a>
        </div>-->
        {{$t('Traveler information')}}
      </div>
      <div class="table border">
        <table>
          <thead>
          <tr>
            <th></th>
            <th>{{$t('TravellerType')}}</th><!--旅客類型-->
            <th>{{$t('Appellation')}}</th><!--稱謂-->
            <th>{{$t('English name')}}</th>
            <th>{{$t('Gender')}}</th>
            <th>{{$t('Certificate Type')}}</th>
            <th>{{$t('Certificate of Issue')}}</th>
            <th>{{$t('Certificate Number')}}</th>
            <th>{{$t('Certificate Validity Period')}}</th>
            <th>{{$t('DateOfBirth')}}</th>
            <th>{{$t('Mobile Number')}}</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item,index) of passengerInfo" :key="item.id">
            <td>{{$t('Passenger')}}{{index+1}}</td>
            <td>{{(item.travelerType+item.isOccupyBed)|dictName('travelerType')}}</td>
            <td>{{item.isCalled|dictName(appellationOptions)}}</td>
            <td>{{item.englishSurname}}/{{item.englishName}}</td>
            <td>{{item.genderType|dictName('gender')}}</td>
            <td>{{item.paperworkType|dictName(certificateTypeOptions)}}</td>
            <td>{{item.issuingCountry|dictName(countryOptions)}}</td>
            <td>{{item.idNumber}}</td>
            <td>{{item.effectiveDate}}</td>
            <td>{{item.birthday}}</td>
            <td class="nowrap">({{item.phoneType}}) {{item.userPhone}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!--聯絡人資料-->
    <div class="fl-group">
      <div class="hd cl">
        <!--<a class="fr btn" @click="openModal('modifyContact')">{{$t('Modify Information')}}</a>-->
        {{$t('ContactInformation')}}
      </div>
      <div class="table border">
        <table>
          <thead>
          <tr>
            <th>{{$t('Appellation')}}</th>
            <th>{{$t('Contact Name')}}</th>
            <th>{{$t('Contact Number')}}</th>
            <th>{{$t('Email')}}</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>{{contactInformation.isCalled|dictName(appellationOptions)}}</td>
            <td>{{contactInformation.englishSurname}}/{{contactInformation.englishName}}</td>
            <td>({{contactInformation.phoneType}}) {{contactInformation.userPhone}}</td>
            <td>{{contactInformation.userEmail}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!--紧急聯絡人資料-->
    <div class="fl-group" v-if="emergencyContact.id">
      <div class="hd cl">
        <!--<a class="fr btn" @click="openModal('modifyContact')">{{$t('Modify Information')}}</a>-->
        {{$t('Emergency Contact')[0]}}
      </div>
      <div class="table border">
        <table>
          <thead>
          <tr>
            <th>{{$t('Name')}}({{$t('English')}})</th>
            <th>{{$t('Name')}}({{$t('Chinese')}})</th>
            <th>{{$t('Mobile Number')}}</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>{{emergencyContact.englishSurname}}/{{emergencyContact.englishName}}</td>
            <td>{{emergencyContact.chineseSurname}}{{emergencyContact.chineseName}}</td>
            <td>({{emergencyContact.phoneType}}) {{emergencyContact.userPhone}}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!--詳細收費-->
    <div class="fl-group">
      <div class="hd">{{$t('Detailed Charge')}}</div>
      <charge-details-table :order="orderInfo"/>
    </div>
    <div class="actions">
      <button class="button button-primary" type="button" @click="onSubmit">
        {{$t('Determining and placing an order')}}
      </button>
    </div>
  </div>
</template>
<script>
  import ChargeDetailsTable from '../components/ChargeDetailsTable';
  import order from '../../../mixins/order';
  import {getDictList} from '../../../api/admin-common';
  import {getOrderDetail, orderMission} from '../../../api/admin-order';
  import {getProductInfoList, getProductPriceListByproductId} from '../../../api/admin-product';

  export default {
    name: 'AdminChangeGroup',
    mixins: [order],
    data() {
      return {
        orderId: '',
        departureDate: '',
        orderInfo: {},
        passengerInfo: [],
        contactInformation: {},
        emergencyContact: {},
        countryOptions: [],
        certificateTypeOptions: [],
        appellationOptions: [],
        areaCodeOptions: [],
        products: [],
        productId: '',
        loadingList: false,
        lineList: [],
        lineId: '',
        priceList: [],
        lineTitle: ''
      };
    },
    computed: {
      productInfo() {
        if (this.productId && this.products.length) {
          return this.products.find(item => item.productId === this.productId);
        }
        return null;
      },
      // 当前日期的路线列表
      lines: function () {
        const lines = (this.priceList.find(item => item.priceDate === this.departureDate) || {}).priceDateStatuses || [];
        this.setDefaultLineId(lines);
        // console.log(lines);
        return lines;
      },
      currentLine: function () {
        return this.lines.find(item => item.lineId === this.lineId);
      },
      priceDates: function () {
        return this.priceList.map(item => {
          if (item.isTopUp === 'N') {
            return new Date(item.priceDate.replace(/-/g, '/')).getTime();
          } else {
            return '';
          }
        });
      },
      pickerOptions: function () {
        return {
          disabledDate: (time) => {
            const timestamp = time.getTime();
            return !this.priceDates.includes(timestamp);
          }
        };
      }
    },
    components: {ChargeDetailsTable},
    created() {
      this.orderId = this.$route.query.id;
      this.getOrderDetail();
      this.getDicts();
    },
    methods: {
      lineChange() {
        const lines = (this.priceList.find(item => item.priceDate === this.departureDate) || {}).priceDateStatuses || [];
        this.lineTitle = (lines.find(item => item.lineId === this.lineId) || {}).lineTitle || '';
      },
      // 获取字典
      getDicts() {
        // 国家/证件签发地
        getDictList('country').then(res => {
          this.countryOptions = res.value || [];
        });

        // 证件类型
        getDictList('certificateType').then(res => {
          this.certificateTypeOptions = res.value || [];
        });

        // 获取称谓选项
        getDictList('appellative').then(res => {
          this.appellationOptions = res.value || [];
        });

        getDictList('phonePrefix').then(res => {
          this.areaCodeOptions = res.value;
        });
      },
      // 获取订单详情
      getOrderDetail() {
        getOrderDetail(this.orderId).then(res => {
          let order = res.value.order || {};
          const contacts = res.value.contacts || [];

          this.orderInfo = this.formatOrder(order);
          this.passengerInfo = res.value.travelers || [];
          this.contactInformation = this.createContactInformation(contacts);
          this.emergencyContact = this.createContactInformation(contacts, 'urgent');
          this.createPassengers();
        });
      },
      // 生成旅客信息表
      createPassengers() {
        const {adultNumber, childNumber, notChildNumber, babyNumber} = this.orderInfo;
        if (!this.passengerInfo.length) {
          this.passengerInfo = [
            ...this.createPassengerList(adultNumber, 'adult', 'Y'),
            ...this.createPassengerList(childNumber, 'child', 'Y'),
            ...this.createPassengerList(notChildNumber, 'child', 'N'),
            ...this.createPassengerList(babyNumber, 'baby', 'N')
          ];

          // 如果客戶訂購旅團時選擇房間數目有0.5时，第一位旅客默认拼房
          // if (roomsNumber % 1 === 0.5) {
          //   this.passengerInfo[0].isFight = 'Y';
          // }
        }
      },
      // 模糊查询
      searchProductsForNumber(query) {
        if (query) {
          this.searchProducts({productCode: query, productTitle: ''});
        } else {
          this.products = [];
        }
      },
      searchProductsForName(query) {
        if (query) {
          this.searchProducts({productCode: '', productTitle: query});
        } else {
          this.products = [];
        }
      },
      searchProducts(data) {
        this.loadingList = true;
        getProductInfoList(data).then(res => {
          this.products = res.value || [];
          this.loadingList = false;
        });
      },
      // 获取产品价格
      getProductPriceList() {
        if (!this.productId) {
          return false;
        }
        getProductPriceListByproductId(this.productId).then(res => {
          const now = new Date().getTime();
          this.priceList = res.value || [];
          this.departureDate = '';
          for (let item of this.priceList) {
            let timestamp = new Date(item.priceDate.replace(/-/g, '/')).getTime();
            if (timestamp > now) {
              this.departureDate = item.priceDate;
              break;
            }
          }
        });
      },
      setDefaultLineId(lines) {
        if (lines.length) {
          if (!lines.find(item => item.lineId === this.lineId && item.lastPeopleNumber > 0)) {
            this.lineId = (lines.find(item => item.lastPeopleNumber > 0) || {}).lineId || '';
            this.lineTitle = (lines.find(item => item.lastPeopleNumber > 0) || {}).lineTitle || '';
          }
        } else {
          this.lineId = '';
        }
      },
      // 消除
      clearData() {
        this.productId = '';
        this.departureDate = '';
        this.lineId = '';
      },
      onSubmit() {
        const data = {
          languageType: this.$i18n.locale,
          orderId: this.orderId,
          productId: this.productId,
          priceDate: this.departureDate,
          lineId: this.lineId
        };

        if (!data.productId) {
          this.$message.warning(this.$t('Please fill out', [`${this.$t('ProductNumber')}/${this.$t('ProductName')}`]));
          return false;
        }
        if (!data.priceDate) {
          this.$message.warning(this.$t('Please choose', [this.$t('DepartureDate')]));
          return false;
        }
        if (!data.lineId) {
          this.$message.warning(this.$t('Please choose', [this.$t('Route')]));
          return false;
        }

        const h = this.$createElement;
        this.$msgbox({
          title: this.$t('Transfer to'),
          message: h('div', {style: 'text-align:left;'}, [
            h('div', null, `${this.$t('ProductNumber')}：${this.productInfo.productCode}`),
            h('div', {style: 'padding-top:10px'}, `${this.$t('ProductName')}：${this.productInfo.title}`),
            h('div', {style: 'padding-top:10px'}, `${this.$t('DepartureDate')}：${this.departureDate}`),
            h('div', {style: 'padding-top:10px'}, `${this.$t('Route')}：${this.lineTitle}`)
          ]),
          center: true,
          showClose: false,
          showCancelButton: true,
          lockScroll: false,
          beforeClose: (action, instance, done) => {
            if (action === 'confirm') {
              instance.confirmButtonLoading = true;
              orderMission(data).then(res => {
                instance.confirmButtonLoading = false;
                done();
                this.$message.success('提交成功!');
                this.$router.go(-1);
              }).catch(() => {
                instance.confirmButtonLoading = false;
              });
            } else {
              done();
            }
          }
        }).then(action => {
        }).catch(() => {
        });
      }
    },
    watch: {
      productId(newValue, oldValue) {
        this.getProductPriceList();
      }
    }
  };
</script>
<style scoped lang="less">
  @import "../../../assets/style/table";
  .main{
    .m-t{margin-top:24px;}
    .m-t8{margin-top:8px;}
    .ipt{
      width:130px;height:32px;padding:0 10px;border:1px solid #d9d9d9;border-radius:4px;
      &:focus{border-color:#ff6f61;}
    }
    .actions{margin-top:20px;text-align:center;}
    .select,
    /deep/ .select{
      width:130px;vertical-align:top;
      .el-input__inner{padding-right:10px;}
    }
    .select{
      /deep/ .el-select__input{margin-left:10px;}
    }
  }
  .h2{font-size:24px;}
  .order-info{
    margin-top:16px;padding:4px 20px 20px;background-color:#f4f4f4;border-radius:4px;
    .item{float:left;width:45%;margin-top:16px;}
  }
  .product-info{margin-top:16px;border:1px solid #f4f4f4;border-radius:4px;}
  .search-wrap{
    padding:16px;
    .item{float:left;margin-right:16px;}
    .label{height:28px;}
  }
  .search-result{
    padding:10px 20px;background-color:#f4f4f4;
    table{
      width:100%;
      td{padding:5px 0;}
    }
    .td-checkbox{vertical-align:middle;}
  }
  .fl-group{
    .hd{
      position:relative;margin:20px 0;padding-left:16px;font-size:24px;
      &:after{position:absolute;top:50%;left:0;width:6px;height:20px;margin-top:-10px;background-color:#ff6f61;content:'';}
    }
    .btn{
      display:inline-block;margin-top:3px;margin-left:8px;padding:0 8px;line-height:28px;color:#1890ff;font-size:14px;font-weight:normal;border:1px solid #1890ff;border-radius:4px;
      &.btn-gray{color:#999;border-color:#ccc;}
    }
  }
</style>
